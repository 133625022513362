html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
@font-face {
  font-family: Open-Sans;
  font-weight: 700;
  src: url("../fonts/Open_Sans/OpenSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: Open-Sans;
  font-style: 400;
  src: url("../fonts/Open_Sans/OpenSans-Regular.ttf") format("truetype");
}
.text-font {
  font-size: 30px;
  color: #111;
}
h1 {
  font-weight: 700;
}
h2 {
  font-weight: 700;
}
h3 {
  font-weight: 700;
}
p {
  line-height: 140%;
}
html {
  font-family: Open-Sans, sans-serif;
  color: #111;
  line-height: 160%;
}
font-size medium-font {
  font-display: auto /* Define how the browser behaves during download */;
}
font-weight 700,
medium-font = (26rem/18),
small-font = (14rem/18),
h1=6.667rem,
.big,
#home #homepage_intro a .text-container .title h2,
#home #homepage_paragraph a .text-container .title h2 {
  font-size: 10vw;
  line-height: 120%;
}
@media (min-width: 600px) {
  font-weight 700,
  medium-font = (26rem/18),
  small-font = (14rem/18),
  h1=6.667rem,
  .big,
  #home #homepage_intro a .text-container .title h2,
  #home #homepage_paragraph a .text-container .title h2 {
    font-size: 4.688rem;
  }
}
.medium {
  font-size: medium-font;
}
.small {
  font-size: small-font;
  line-height: 150%;
}
strong {
  font-weight: 700;
}
.black-title {
  padding: 3rem;
  color: #111;
  font-weight: 700;
  font-size: 1.555555555555556rem;
}
@media (max-width: 599px) {
  footer {
    background: #58ffcc;
  }
  img.logo {
    height: 100px;
  }
  p {
    width: 100%;
  }
  li {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid /* Firefox */;
    break-inside: avoid;
    list-style-type: none;
  }
}
a[href] color black {
  float: none;
  display: inline-flex;
}
#logo-supsi {
  padding: 1rem 0 1rem 0;
}
#supsi {
  padding-bottom: 2rem;
  width: 100%;
}
#hslu {
  padding-bottom: 1rem;
}
.logo {
  padding-top: 1rem;
}
#logo-hslu {
  padding: 1rem 0 0rem 0;
}
@media (min-width: 600px) {
  #footer {
    background-color: #58ffcc;
  }
  #supsi {
    float: left;
    display: inline;
    padding-bottom: 2rem;
    padding-left: 2rem;
    width: 50%;
  }
  img.logo {
    height: 100px;
  }
  li {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid /* Firefox */;
    break-inside: avoid;
    list-style-type: none;
  }
}
a[href] {
  color: #111;
}
.content-2 {
  padding: 1rem 1rem 1rem 1rem;
}
#logo-supsi {
  padding: 0rem 0 2rem 0;
}
#logo-hslu {
  padding: 0rem 0 2rem 0;
}
#hslu {
  display: block;
}
#hslu padding-bottom 1rem {
  float: left;
}
@media (max-width: 599px) {
  .content-1 {
    display: block;
    padding: 2rem 1rem 2rem 1rem;
    border-bottom: 1px solid #969798 #111;
  }
  .content-1 .social {
    width: 100%;
    padding-left: 0rem;
    padding-bottom: 1rem;
    float: none;
    display: block;
    height: 4%;
  }
}
@media (min-width: 600px) {
  .content-2 {
    display: flex;
  }
}
@media (min-width: 600px) {
  .content-1 {
    display: block;
    padding: 2rem 1rem 2rem 1rem;
    border-bottom: 1px solid #969798 #111;
  }
  .content-1 .social {
    width: 100%;
    padding-left: 2rem;
    padding-bottom: 1rem;
    float: left;
    display: block;
    width: 50%;
    height: 5%;
  }
}
@media (max-width: 599px) {
  #sdn {
    padding: 1rem 1rem 1rem 1rem;
    float: left;
    display: block;
    width: 100%;
    height: 5%;
    background-color: #30e3e3;
  }
  #sdn .logo-sdn {
    height: 15px;
  }
}
@media (min-width: 600px) {
  #sdn {
    padding: 2.5rem 1rem 1rem 3rem;
    float: left;
    display: block;
    width: 100%;
    height: 9%;
    background-color: #30e3e3;
  }
  #sdn .logo-sdn {
    height: 15px;
  }
}
@media (max-width: 599px) {
  .link-mail {
    padding: 2em 0 0rem 0;
  }
}
@media (min-width: 600px) {
  .link-mail {
    padding: 0rem 0 0rem 0;
  }
}
@media (min-width: 1300px) {
  #section {
    padding-top: 170px;
  }
}
header.header {
  font-size: 20px;
  background-color: #e12df8;
  z-index: 3;
  position: fixed;
  width: 100%;
}
header.header a[href] {
  text-decoration: none;
}
header.header .fa {
  font-size: 20px;
}
header.header #registration {
  background-color: #fff;
  position: fixed;
  right: 1rem;
  top: 1rem;
  padding: 0.5rem;
  float: right;
  border: none;
  margin: 4px 2px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border-radius: 4px;
  margin: 2px 2px;
  position: absolute;
  top: 7px;
  right: 1rem;
  cursor: pointer;
}
header.header #hamburger {
  position: fixed;
  top: 1rem;
  left: 1rem;
}
header.header #topbar {
  font-weight: 700;
  width: 100%;
  padding: 1rem;
  padding-left: 3rem;
  background-color: #e12df8;
}
header.header #topbar-desktop {
  display: none;
}
header.header nav#menu {
  display: none;
  background-color: #e12df8;
  width: 100%;
  list-style-type: none;
  text-decoration: none;
}
header.header nav#menu ul {
  margin-top: 1rem;
}
header.header nav#menu ul li {
  height: 2rem;
  display: block;
  padding-left: 1rem;
}
header.header nav#menu ul a.active {
  font-weight: 700;
}
header.header nav#menu.open {
  display: block;
}
@media (min-width: 1300px) {
  header.header #registration {
    top: 5.7rem;
    right: 3rem;
    background-color: #fff;
    color: #111;
    border: 2px solid #e12df8;
    padding: 1rem 2rem 1rem 2rem;
    border-radius: 4px;
    font-size: 14px;
  }
  header.header #topbar {
    display: none;
  }
  header.header #topbar-desktop {
    font-size: 16px;
    font-weight: 700;
    display: block;
    width: 100%;
    padding: 1.4rem 0.5rem 0.5rem 3rem;
    padding-left: 3rem;
    background-color: #e12df8;
  }
  header.header #hamburger {
    display: none;
  }
  header.header nav#menu {
    background-color: #30e3e3;
    display: block;
    height: 7rem;
  }
  header.header nav#menu ul {
    padding-top: 1rem;
    display: flex;
    margin-left: 1rem;
  }
  header.header nav#menu ul li {
    display: inline-block;
    padding: 2rem;
    padding-bottom: 3.7rem;
  }
  header.header nav#menu ul a.active {
    border-bottom: 6px solid #e12df8;
    font-weight: 700;
  }
}
.time_zone_panel {
  font-size: 0.8rem;
}
@media (min-width: 600px) {
  .time_zone_panel {
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
  }
}
.time_zone_panel .helper {
  margin-bottom: 1.5rem;
  width: 100%;
}
@media (min-width: 600px) {
  .time_zone_panel .helper {
    width: 40%;
  }
}
.time_zone_panel .time_switch {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media (min-width: 600px) {
  .time_zone_panel .time_switch {
    width: 50%;
  }
}
@media (min-width: 900px) {
  .time_zone_panel .time_switch {
    width: 40%;
  }
}
.time_zone_panel .time_switch #swiss_time,
.time_zone_panel .time_switch .your_local_time {
  width: 80px;
  margin: 0;
}
@media (min-width: 600px) {
  .time_zone_panel .time_switch #swiss_time,
  .time_zone_panel .time_switch .your_local_time {
    width: 120px;
  }
}
@media (min-width: 900px) {
  .time_zone_panel .time_switch #swiss_time,
  .time_zone_panel .time_switch .your_local_time {
    width: 140px;
  }
}
.time_zone_panel .time_switch #swiss_time {
  margin: 0 10px 0 0;
  font-weight: 700;
}
.time_zone_panel .time_switch .your_local_time {
  margin: 0 0 0 10px;
}
.time_zone_panel .time_switch .switch {
  margin: 0 20px;
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.time_zone_panel .time_switch .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #e12df8;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
.title {
  color: #e67753;
  font-weight: 700;
  font-size: 1.555555555555556rem;
}
.container-bio h1 {
  font-size: 2rem;
  padding-bottom: 1rem;
  line-height: 120%;
}
.container-bio #bio-text {
  font-size: 1.5rem;
  padding-top: 2.5rem;
}
.container-bio #bio-social {
  display: block;
  margin-top: 40px;
}
@media (min-width: 900px) {
  .container-bio #bio-social {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}
.container-bio #bio-social li {
  padding-right: 50px;
  margin-bottom: 20px;
}
.container-bio .speaker_category {
  margin-bottom: 10px;
}
@media (max-width: 599px) {
  .bio-affiliation {
    font-weight: 700;
  }
  .bio-content {
    padding: 3rem 0 0 1rem;
    display: inline-block;
    padding-bottom: 2rem;
  }
  #bio-pic {
    float: left;
    display: block;
  }
  .container-bio {
    padding-top: 3rem;
    display: block;
    float: left;
    margin-right: 1rem;
  }
  #bio-text {
    width: 100%;
  }
  img.resize {
    width: 250px;
    height: 250px;
  }
}
@media (min-width: 600px) {
  .bio-affiliation {
    font-weight: 700;
  }
  .bio-content {
    padding: 3rem 0 0 2rem;
    display: inline-block;
    padding-bottom: 2rem;
  }
  #bio-pic {
    float: left;
    display: block;
  }
  .container-bio {
    padding-top: 2rem;
    display: block;
    float: left;
    margin-right: 1rem;
  }
  #bio-text {
    width: 100%;
  }
  img.resize {
    width: 300px;
    height: 300px;
  }
}
@media (min-width: 900px) {
  .bio-affiliation {
    font-weight: 700;
  }
  .bio-content {
    padding: 3rem 0 3rem 2rem;
    display: inline-block;
  }
  #bio-pic {
    float: left;
    display: block;
  }
  .container-bio {
    padding-top: 0rem;
    padding-left: 3rem;
    display: block;
    float: left;
    width: 60%;
  }
  img.resize {
    width: 350px;
    height: 350px;
  }
}
@media (min-width: 1300px) {
  .bio-affiliation {
    font-weight: 700;
  }
  .bio-content {
    padding: 3rem;
    display: inline-block;
  }
  #bio-pic {
    float: left;
    display: block;
  }
  .container-bio {
    padding-top: 0rem;
    padding-left: 3rem;
    display: block;
    float: left;
    width: 60%;
  }
  img.resize {
    width: 400px;
    height: 400px;
  }
}
.contacts {
  height: 24rem;
  padding: 3rem;
}
.contacts a:link {
  color: #111;
}
.credits .header {
  padding: 2rem 0 1rem;
  margin-bottom: 2rem;
  border-bottom: 4px solid #111;
  font-size: 2rem;
  color: #e12df8;
}
.credits .schools {
  margin-bottom: 4rem;
}
.credits .schools p {
  margin-bottom: 1.5rem;
}
.credits .people div {
  margin-bottom: 1.5rem;
}
.credits .people ul {
  margin-bottom: 1.5rem;
}
.credits .people ul li {
  margin-bottom: 0.7rem;
}
@media (min-width: 600px) {
  .credits .people ul li {
    margin-bottom: 0.2rem;
  }
}
.error {
  margin: 0 0 3rem 0;
}
.error p {
  font-size: 1.5rem;
  margin: 0 0 1rem;
}
@media (min-width: 600px) {
  .error p {
    font-size: 2rem;
  }
}
.content {
  padding: 2rem 1rem;
  line-height: 1.4em;
}
@media (min-width: 600px) {
  .content {
    padding: 2rem 2rem;
  }
}
@media (min-width: 1300px) {
  .content {
    padding: 2rem 3rem;
  }
}
.content a {
  color: #e12df8;
}
.parent_page {
  color: #111;
}
.page_section {
  font-size: 2rem;
  padding-bottom: 1rem;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
.row {
  margin-left: -10px;
  margin-right: -10px;
}
.row:after {
  content: " ";
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}
.column {
  display: inline;
  vertical-align: baseline;
  padding-left: 10px;
  padding-right: 10px;
  float: left;
}
.column-1 {
  width: 8.333333333333332%;
}
.column-2 {
  width: 16.666666666666664%;
}
.column-3 {
  width: 25%;
}
.column-4 {
  width: 33.33333333333333%;
}
.column-5 {
  width: 41.66666666666667%;
}
.column-6 {
  width: 50%;
}
.column-7 {
  width: 58.333333333333336%;
}
.column-8 {
  width: 66.66666666666666%;
}
.column-9 {
  width: 75%;
}
.column-10 {
  width: 83.33333333333334%;
}
.column-11 {
  width: 91.66666666666666%;
}
.column-12 {
  width: 100%;
}
.column.clr {
  clear: both;
}
.column div {
  text-align: left;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: #fff;
}
#home h1 {
  display: none;
}
#home #homepage_intro,
#home #homepage_paragraph {
  display: none;
}
#home #homepage_intro a:link,
#home #homepage_paragraph a:link {
  border: none;
  padding-bottom: 0;
  box-sizing: border-box;
}
#home #homepage_intro a:hover .cover,
#home #homepage_paragraph a:hover .cover {
  transform: translate(0, -0.5rem);
}
#home #homepage_intro a:hover .title,
#home #homepage_paragraph a:hover .title {
  display: block !important;
  transform: translate(0, -0.5rem) !important;
}
#home #homepage_intro a:hover .title h2,
#home #homepage_paragraph a:hover .title h2 {
  border-color: #111 !important;
}
#home #homepage_intro a,
#home #homepage_paragraph a {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
#home #homepage_intro a .cover,
#home #homepage_paragraph a .cover {
  z-index: 2;
  width: 100%;
  background-size: cover !important;
  margin-bottom: 0.2rem;
  margin-top: 1rem;
  transform: translate(0, 0);
  transition: all 0.2s ease-in-out;
}
#home #homepage_intro a .cover:after,
#home #homepage_paragraph a .cover:after {
  content: "";
  display: block;
  padding-bottom: 75%;
}
#home #homepage_intro a .text-container,
#home #homepage_paragraph a .text-container {
  order: 1;
  display: block;
  width: 100%;
}
#home #homepage_intro a .text-container .title,
#home #homepage_paragraph a .text-container .title {
  margin-top: 0.5rem;
  display: block;
  text-align: center;
  transition: all 0.2s ease-in-out;
}
#home #homepage_intro a .text-container .title h2,
#home #homepage_paragraph a .text-container .title h2 {
  display: inline;
  line-height: 130%;
  font-size: medium;
}
#home #homepage_intro a .text-container .subtitle,
#home #homepage_paragraph a .text-container .subtitle {
  display: none;
  line-height: 180%;
}
#home #homepage_intro li + li,
#home #homepage_paragraph li + li {
  margin-top: 2rem;
}
@media (min-width: 600px) {
  #home #projects a:hover .cover,
  #home #projects a:hover .title {
    transform: translate(0, 0) !important;
  }
  #home #projects a .cover:after {
    padding-bottom: 70%;
  }
  #home #projects a .text-container {
    order: 1;
    width: 100%;
    margin-top: 0.5rem;
  }
  #home #projects a .text-container .title {
    width: 100%;
    display: block;
    text-align: left;
    transform: translate(0, 0);
  }
  #home #projects a .text-container .title h2 {
    display: inline;
    font-size: medium-font;
  }
  #home #projects li + li {
    margin-top: 5rem;
  }
}
@media (min-width: 900px) {
  #home #projects a .cover {
    margin-top: 0;
    float: left;
    width: 46%;
  }
  #home #projects a .cover:after {
    padding-bottom: 75%;
  }
  #home #projects a .text-container {
    order: 1;
    float: right;
    width: 46%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
  }
  #home #projects a .text-container .title {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    transform: translate(0, 0);
  }
  #home #projects a .text-container .title h2 {
    display: inline;
    font-size: 4.688rem;
    font-size: 4vw;
  }
  #home #projects a .text-container .subtitle {
    display: block;
  }
  #home li:after,
  #home a:after {
    content: "";
    clear: both;
    display: block;
  }
}
@media (min-width: 1300px) {
  #home #projects a .text-container .title h2 {
    font-size: 4.688rem;
  }
}
.intro {
  margin-bottom: 6rem;
}
.intro .intro_header {
  border-bottom: 4px solid #111;
  padding: 1rem 0;
  margin-bottom: 2rem;
}
.intro .intro_header h4 {
  font-size: 2rem;
  font-weight: 700;
  color: #e12df8;
}
.intro .text {
  font-size: 1.3rem;
  column-count: 1;
}
@media (min-width: 600px) {
  .intro .text {
    font-size: 1.6rem;
  }
}
@media (min-width: 900px) {
  .intro .text {
    font-size: 1.8rem;
    column-gap: 2rem;
    column-count: 2;
  }
}
.updates {
  border-top: 4px solid #111;
  margin-bottom: 4rem;
}
.updates ul {
  columns: 1;
  column-gap: 2rem;
  padding-top: 2rem;
}
@media (min-width: 600px) {
  .updates ul {
    columns: 1;
  }
}
.updates ul li {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid /* Firefox */;
  break-inside: avoid;
  border-bottom: 1px solid #969798;
  padding-bottom: 2rem;
  margin-bottom: 1rem;
}
.updates h5 {
  font-size: 2rem;
  margin: 0 0 1rem 0;
  font-weight: 700;
  color: #e12df8;
}
.updates .update_text {
  font-size: 1.3rem;
  margin-left: 0;
}
@media (min-width: 600px) {
  .updates .update_text {
    margin-left: 25%;
  }
}
.main-text {
  padding-top: 1.35rem;
  width: 90%;
}
.sub_panel_home {
  height: 300px;
  background-color: #58ffcc;
  padding: 2rem;
}
.sub_panel {
  color: #111;
  font-size: 2rem;
  background-color: #58ffcc;
  padding: 10rem 1rem 5rem;
  line-height: 140%;
}
@media (min-width: 600px) {
  .sub_panel {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1300px) {
  .sub_panel {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
.sub_panel h1 {
  width: 100%;
}
@media (min-width: 600px) {
  .sub_panel h1 {
    width: 75%;
  }
}
.sub_panel h4 {
  padding-bottom: 1rem;
  font-size: 1.2rem;
}
.sub_panel h4 a {
  text-decoration: none;
}
.panel {
  height: 450px;
  background-color: #58ffcc;
  color: #111;
}
.panel {
  line-height: 300%;
}
@media (max-width: 599px) {
  .panel {
    padding: 1rem;
  }
}
@media (min-width: 600px) {
  .panel {
    padding: 2rem;
  }
}
@media (min-width: 900px) {
  .panel {
    padding: 2rem;
  }
}
@media (min-width: 1300px) {
  .panel {
    padding: 3rem;
  }
}
.panel #main_title {
  font-size: 2.5rem;
  padding: 3rem 0 1.5rem;
}
@media (min-width: 600px) {
  .panel #main_title {
    font-size: 3.5rem;
    padding: 3rem 0 1.5rem;
  }
}
.panel #subtitle {
  font-size: 1.2rem;
  padding: 0 0 2rem;
  line-height: 150%;
}
@media (min-width: 600px) {
  .panel #subtitle {
    font-size: 2rem;
  }
}
.panel #main_organizer {
  font-weight: 400;
  font-size: 1.2rem;
}
@media (min-width: 600px) {
  .panel #main_organizer {
    font-size: 1.5rem;
  }
}
.program div a {
  text-decoration: none;
}
.parent_page {
  position: relative;
  top: -15px;
  left: 15px;
  font-size: 1.8rem;
}
.program_overview {
  background-color: #f3f3f3;
}
.program_overview .the_day {
  border-top: 4px solid #111;
  border-bottom: 1px solid #969798;
  padding: 2rem 0 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}
.program_overview .the_day h3 {
  font-size: 2rem;
  color: #e12df8;
  width: 80%;
}
.program_overview .the_day .link {
  position: relative;
  top: -10px;
}
.one_day {
  margin-bottom: 5rem;
}
.one_day em {
  font-style: italic;
}
.one_day .event {
  border-bottom: 1px solid #969798;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}
.one_day .event .highlight {
  color: #e12df8;
}
@media (min-width: 600px) {
  .one_day .event {
    display: flex;
    justify-content: space-between;
  }
}
.one_day .event .time {
  padding-bottom: 20px;
  font-weight: 700;
}
@media (min-width: 600px) {
  .one_day .event .time {
    width: 25%;
    padding-bottom: 0;
  }
}
.one_day .event .session {
  padding-bottom: 20px;
}
@media (min-width: 600px) {
  .one_day .event .session {
    width: 70%;
    padding-bottom: 0;
  }
}
.one_day .event .session .session_name {
  font-weight: 700;
}
.specific_day .break_event .info {
  border-bottom: none !important;
}
.specific_day .event {
  border-top: 2px solid #111;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}
.specific_day .event .info {
  padding: 1rem 0 0;
  border-bottom: 1px solid #969798;
}
@media (min-width: 600px) {
  .specific_day .event .info {
    display: flex;
    justify-content: flex-start;
  }
}
.specific_day .event .info .event_time {
  padding-bottom: 20px;
  font-weight: 700;
}
@media (min-width: 600px) {
  .specific_day .event .info .event_time {
    width: 20%;
    padding: 0;
  }
}
.specific_day .event .info .event_title_link {
  display: flex;
  justify-content: space-between;
}
@media (min-width: 600px) {
  .specific_day .event .info .event_title_link {
    width: 80%;
  }
}
.specific_day .event .info .event_title_link .event_title_cat {
  display: block;
  width: 100%;
  margin-bottom: 0.8rem;
}
@media (min-width: 600px) {
  .specific_day .event .info .event_title_link .event_title_cat {
    display: flex;
    justify-content: flex-start;
  }
}
.specific_day .event .info .event_title_link .event_title_cat .event_title {
  font-weight: 700;
  padding: 0 2rem 10px 0;
}
@media (min-width: 600px) {
  .specific_day .event .info .event_title_link .event_title_cat .event_title {
    width: 75%;
    padding-bottom: 0;
  }
}
.specific_day .event .info .event_title_link .event_link_to_page {
  width: 15%;
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: -10px;
}
@media (min-width: 600px) {
  .specific_day .event .info .event_title_link .event_category {
    width: 25%;
  }
}
.specific_day .event .detail {
  padding: 1rem 0 0;
}
@media (min-width: 600px) {
  .specific_day .event .detail {
    width: 70%;
    padding-bottom: 0;
    margin: 0 0 0 20%;
  }
}
.event_page {
  background-color: #f3f3f3;
}
.event_page h3 {
  font-size: 1rem;
  font-weight: 700;
}
.event_page h4 {
  font-size: 1.5rem;
  font-weight: 700;
}
.event_page .header {
  display: flex;
  justify-content: space-between;
}
@media (min-width: 600px) {
  .event_page .header .time_cat {
    width: 70%;
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
  }
}
.event_page .header .time_cat .event_time {
  font-weight: 700;
  padding-bottom: 10px;
}
.event_page .header .event_link {
  text-align: right;
}
@media (min-width: 600px) {
  .event_page .header .event_link {
    width: 20%;
  }
}
@media (min-width: 600px) {
  .event_page .detail .abstract_download {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5rem;
  }
}
@media (min-width: 600px) {
  .event_page .detail .abstract_download .abstract {
    width: 75%;
  }
}
.event_page .detail .abstract_download .keywords {
  font-size: 0.8rem;
}
.event_page .detail .abstract_download .download {
  margin: 2rem 0 5rem;
  display: flex;
  justify-content: flex-start;
}
@media (min-width: 600px) {
  .event_page .detail .abstract_download .download {
    justify-content: flex-end;
    width: 20%;
    text-align: right;
    margin: 0 0 5rem;
  }
}
.event_page .detail .abstract_download .download div {
  margin: 0 10px 0 0;
}
@media (min-width: 600px) {
  .event_page .detail .abstract_download .download div {
    margin: 0 0 0 10px;
  }
}
.event_page .detail .abstract_download .event_keywords {
  font-weight: 700;
  margin: 0.2rem 0 2.5rem;
  font-size: 1.5rem;
  line-height: 140%;
}
.event_page .detail .abstract_download .event_description {
  margin-bottom: 20px;
  font-size: 1.2rem;
}
.event_page .detail .event_speakers {
  margin-bottom: 5rem;
}
.event_page .detail .event_speakers ul {
  -moz-column-count: 1;
  column-count: 1;
}
@media (min-width: 600px) {
  .event_page .detail .event_speakers ul {
    width: 75%;
    -moz-column-count: 2;
    column-count: 2;
    -moz-column-gap: 2rem;
    column-gap: 2rem;
  }
}
.event_page .detail .event_speakers ul a {
  color: #111;
  text-decoration: none;
}
.event_page .detail .event_speakers ul a h3 {
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 120%;
}
.event_page .detail .event_speakers ul a li {
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #969798;
  margin-bottom: 0.2rem;
  page-break-inside: avoid;
  -moz-column-break-inside: avoid;
  break-inside: avoid;
}
.event_page .detail .event_detail {
  margin-bottom: 5rem;
}
.event_page .detail .event_detail p {
  margin-bottom: 1rem;
}
@media (min-width: 600px) {
  .event_page .detail .event_detail {
    width: 75%;
  }
}
@media (min-width: 600px) {
  .event_page .detail .event_papers {
    width: 75%;
  }
}
.event_page .detail .event_papers li {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #969798;
}
.event_page .detail .event_papers .the_title {
  font-weight: 700;
}
.event_page .detail .event_papers .the_authors {
  margin-bottom: 20px;
}
.open_section {
  border-bottom: 4px solid #111;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.category {
  text-transform: uppercase;
  font-weight: 700;
}
.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  top: 2px;
  position: relative;
}
.workshop_category_circle {
  background-color: #003bff;
}
.panel_category_circle {
  background-color: #e12df8;
}
.workshop_category {
  color: #003bff;
}
.panel_category {
  color: #e12df8;
}
.speakers .people_group {
  margin-bottom: 5rem;
}
.speakers .people_group .header {
  font-size: 2rem;
  color: #e67753;
}
.speakers .people_group .group_header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #969798;
  padding: 0.8rem 0 0.5rem;
}
.speakers .people_group .group_header .speakers_header {
  width: 75%;
}
.speakers .people_group .group_header .speakers_header h4 {
  font-weight: 700;
}
.speakers .people_group .group_header .link_to_speaker_details {
  position: relative;
  top: -10px;
}
.speakers .people_group ul {
  margin: 0.8rem 0 3rem;
}
.speakers .people_group ul .person {
  display: block;
  margin-bottom: 0.2rem;
}
@media (min-width: 900px) {
  #group-name,
  color black,
  +media(mobile),
  width 100% {
    width: 50%;
  }
}
@media (min-width: 1300px) {
  #group-name,
  color black,
  +media(mobile),
  width 100% {
    width: 33%;
  }
}
@media (min-width: 900px) {
  .skeakers_overview .speakers_list {
    column-count: 2;
    column-gap: 2rem;
  }
}
.skeakers_overview .speakers_list li {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}
.skeakers_overview .panel_speakers,
.skeakers_overview .workshop_speakers {
  margin-bottom: 5rem;
}
.skeakers_overview a {
  text-decoration: none;
  color: #111;
}
.skeakers_overview .speakers_header {
  padding: 2rem 0 1rem;
  margin-bottom: 1rem;
  border-bottom: 4px solid #111;
}
.skeakers_overview .speakers_header h2 {
  font-size: 2rem;
  color: #e12df8;
  line-height: 120%;
}
.skeakers_overview .speaker_line a {
  display: flex;
  justify-content: space-between;
  padding: 0.7rem 0;
  border-bottom: 1px solid #969798;
}
@media (min-width: 900px) {
  .skeakers_overview .speaker_line a {
    padding: 1rem 0;
  }
}
.skeakers_overview .speaker_line a .speaker_name_aff {
  padding-right: 1.5rem;
}
.skeakers_overview .speaker_line a .speaker_name_aff .speaker_name {
  font-size: 1.2rem;
  margin-bottom: 0.2rem;
  font-weight: normal;
}
@media (min-width: 900px) {
  .skeakers_overview .speaker_line a .speaker_name_aff .speaker_name {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
}
.skeakers_overview .speaker_line a .speaker_name_aff .speaker_affiliation {
  font-size: 0.8rem;
}
@media (min-width: 900px) {
  .skeakers_overview .speaker_line a .speaker_name_aff .speaker_affiliation {
    font-size: 1rem;
  }
}
.skeakers_overview .speaker_line a .speaker_name_aff .speaker_link {
  width: 40px;
}
@media (min-width: 900px) {
  .skeakers_overview .speaker_line a .speaker_name_aff .speaker_link {
    width: 70px;
  }
}
