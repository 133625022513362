.panel

  	height 450px
  	background-color secondary-color
  	
  	color black
.panel
	line-height 300%
	+media(mobile)
		padding 1rem
	
	+media(tablet-v)
		padding 2rem

	+media(tablet-h)
		padding 2rem
	+media(desktop)
		padding 3rem

	#main_title
		font-size 2.5rem
		padding 3rem 0 1.5rem

		+media(tablet-v)
			font-size 3.5rem
			padding 3rem 0 1.5rem

	#subtitle
		font-size 1.2rem
		padding 0 0 2rem
		line-height 150%

		+media(tablet-v)
			font-size 2rem

	#main_organizer
		font-weight regular
		font-size 1.2rem

		+media(tablet-v)
			font-size 1.5rem