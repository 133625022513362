
// #metrics
gap = 10px
gridsize = 12

.content
  padding 2rem 1rem
  line-height 1.4em

  +media(tablet-v)
    padding 2rem 2rem

  +media(desktop)
    padding 2rem 3rem
  a
    color topbar-color

.parent_page
  color black


.page_section
  font-size 2rem
  padding-bottom 1rem

html
  box-sizing: border-box
*, *:before, *:after
  box-sizing: inherit
.row
  margin-left: (-1 * gap)
  margin-right: (-1 * gap)
  &:after
    content: " "
    visibility: hidden
    display: block
    height: 0
    clear: both
.column
  display: inline
  vertical-align:baseline
  padding-left: gap
  padding-right: gap
  float: left
//default styles
for i in (1)..(gridsize)
  .column-{i}
    width: (i / gridsize * 100%)
//and finally some demo styles
.column.clr
  clear: both
.column div
  text-align: left
  border-radius: 5px
  padding-top: 10px
  padding-bottom: 10px
  margin-bottom: 10px
  color: white
	
 