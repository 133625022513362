// time switch

.time_zone_panel
	font-size 0.8rem

	+media(tablet-v)
		font-size 1rem
		display flex
		justify-content space-between

	.helper
		margin-bottom 1.5rem
		width 100%

		+media(tablet-v)
			//margin 1rem 0 1rem
			width 40%

	.time_switch
		display flex
		justify-content space-between
		width 100%

		+media(tablet-v)
			width 50%

		+media(tablet-h)
			width 40%

		#swiss_time, .your_local_time
			width 80px
			margin 0

			+media(tablet-v)
				width 120px

			+media(tablet-h)
				width 140px

		#swiss_time
			margin 0 10px 0 0
			font-weight bold

		.your_local_time
			margin 0 0 0 10px

		.switch 
			margin 0 20px
			position: relative
			display: inline-block
			width: 60px
			height: 34px

		.switch input  
			opacity: 0
			width: 0
			height: 0

.slider 
	position: absolute
	cursor: pointer
	top: 0
	left: 0
	right: 0
	bottom: 0
	background-color: #ccc
	-webkit-transition: .4s
	transition: .4s

.slider:before 
	position: absolute
	content: ""
	height: 26px
	width: 26px
	left: 4px
	bottom: 4px
	background-color: white
	-webkit-transition: .4s
	transition: .4s

input:checked + .slider 
	background-color topbar-color


input:focus + .slider 
	box-shadow 0 0 1px #2196F3


input:checked + .slider:before 
	-webkit-transform translateX(26px)
	-ms-transform translateX(26px)
	transform translateX(26px)

/* Rounded sliders */
.slider.round 
	border-radius: 34px

.slider.round:before 
	border-radius: 50%
