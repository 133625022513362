// definition of the breakpoints (mobile first approach -> min-width)
media_queries = {
	mobile: "(max-width: 599px)",
	tablet-v: "(min-width: 600px)",
	tablet-h: "(min-width: 900px)",
	desktop: "(min-width: 1300px)",
}

//make the mixin function
media(breakpoints)
	conditions = ()
	for breakpoint in breakpoints
		push(conditions, media_queries[breakpoint])
	conditions = join(", ", conditions)
	@media conditions
		{block}