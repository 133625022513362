.container-bio
    h1
        font-size 2rem
        padding-bottom 1rem
        line-height 120%

    #bio-text
        font-size 1.5rem
        padding-top 2.5rem

    #bio-social
        display block
        margin-top: 40px

        +media(tablet-h)
            display flex
            justify-content flex-start
            flex-wrap: wrap

        li
            padding-right: 50px
            margin-bottom: 20px

    .speaker_category
        margin-bottom: 10px

+media(	mobile)
    .bio-affiliation
        font-weight: bold   

    .bio-content
        padding 3rem 0 0 1rem
        display inline-block
        padding-bottom 2rem
        
    #bio-pic
        float: left
        display block
    .container-bio
        padding-top 3rem
        display block
        float left
        margin-right 1rem

    #bio-text
        width 100%

    img.resize 
        width:250px
        height:250px

+media(	tablet-v)
    .bio-affiliation
        font-weight: bold

    .bio-content
        padding 3rem 0 0 2rem
        display inline-block
        padding-bottom 2rem
        
    #bio-pic
        float: left
        display block

    .container-bio
        padding-top 2rem
        display block
        float left
        margin-right 1rem

    #bio-text
        width 100%

    img.resize 
        width:300px
        height:300px        
 
+media(	tablet-h)
    .bio-affiliation
        font-weight: bold

    .bio-content
        padding 3rem 0 3rem 2rem
        display inline-block

    #bio-pic
        float: left
        display block
    .container-bio
        padding-top 0rem
        padding-left 3rem
        display block
        float left
        width 60%

    img.resize 
        width:350px
        height:350px  

+media(	desktop)
    .bio-affiliation
        font-weight: bold
    .bio-content
        padding 3rem
        display inline-block
      
    #bio-pic
        float: left
        display block

    .container-bio
        padding-top 0rem
        padding-left 3rem
        display block
        float left
        width 60%
    img.resize 
        width:400px
        height:400px
