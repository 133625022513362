+media(mobile)
  footer 
    background secondary-color
    
  img.logo
    height 100px

  p
    width 100%
    // font-size (15rem/18)
    // line-height 1.6
    // color: black

  li
    -webkit-column-break-inside avoid
    page-break-inside avoid /* Firefox */
    break-inside avoid
    list-style-type: none

	a[href]
  	color black


    float: none
    display: inline-flex


  #logo-supsi
    padding 1rem 0 1rem 0

  #supsi
    padding-bottom 2rem  
    width 100%

  #hslu
    padding-bottom 1rem  
  .logo
    padding-top 1rem
  #logo-hslu
    padding 1rem 0 0rem 0
  
   



+media(tablet-v)
  #footer
    background-color secondary-color
  #supsi
    float: left
    display inline
    padding-bottom 2rem  
    padding-left 2rem
    width 50%

    
  img.logo
    height 100px
  p
    // font-size (15rem/18)
    // line-height 1.6
    // color: black
  li
    -webkit-column-break-inside avoid
    page-break-inside avoid /* Firefox */
    break-inside avoid
    list-style-type: none
	a[href]
  	color black
  // .link-mail
    // padding 0rem 0 0rem 0


  .content-2
    padding 1rem 1rem 1rem 1rem

  #logo-supsi
    padding 0rem 0 2rem 0
  #logo-hslu
    padding 0rem 0 2rem 0


  #hslu
    padding-bottom 1rem  
     float: left
    display block
  


.content-1 
  +media(mobile)
    
    display: block
    padding 2rem 1rem 2rem 1rem
    border-bottom 1px solid line black
    .social
      width 100%
      padding-left 0rem
      padding-bottom 1rem    
      float: none
      display: block
      height 4%

.content-2
  +media(tablet-v)
    display: flex
.content-1 
  +media(tablet-v)
    
    display: block
    padding 2rem 1rem 2rem 1rem
    border-bottom 1px solid line black
    .social
      width 100%
      padding-left 2rem
      padding-bottom 1rem    
      float: left
      display: block
      width 50%
      height 5%
   


#sdn
  +media(mobile)
    padding 1rem 1rem 1rem 1rem
    float: left
    display: block
    width 100%
    height 5%
    background-color primary-color
    .logo-sdn  
      height 15px

  +media(tablet-v)
    padding 2.5rem 1rem 1rem 3rem
    float: left
    display: block
    width 100%
    height 9%
    background-color primary-color
    .logo-sdn  
      height 15px

  
.link-mail
    
  +media(mobile)
      padding 2em 0 0rem 0

  +media(tablet-v)
        padding 0rem 0 0rem 0
