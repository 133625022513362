#home
	h1
		display none

	#homepage_intro, #homepage_paragraph
		display none
 
		a:link
			border none
			padding-bottom 0
			box-sizing border-box

		a:hover
			.cover
				transform translate(0, -0.5rem)

			.title
				display block !important
				transform translate(0, -0.5rem) !important

				h2
					border-color black !important
		a
			display flex
			flex-wrap wrap
			justify-content space-between

			.cover
				z-index 2
				width 100%
				background-size cover !important
				margin-bottom 0.2rem
				margin-top 1rem
				transform translate(0, 0)
				transition all .2s ease-in-out

			.cover:after
				content ""
				display block
				padding-bottom 75%

			.text-container
				order 1
				display block
				width 100%

				.title
					margin-top 0.5rem
					display block
					text-align center
					//transform translate(0, -3rem)
					transition all .2s ease-in-out


					h2
						display inline
						@extends .big
						line-height 130%
						// border-color white
						font-size medium

				.subtitle
					display none
					line-height 180%

		li + li
			margin-top 2rem


	+media(tablet-v)
		#projects

			a:hover
				.cover, .title
					transform translate(0, 0) !important

			a
				.cover:after
					padding-bottom 70%

				.text-container
					order 1
					width 100%
					margin-top 0.5rem

					.title
						width 100%
						display block
						text-align left
						transform translate(0, 0)
						h2
							display inline
							font-size medium-font
		#projects
			li + li
				margin-top 5rem

	+media(tablet-h)
		/*
		#projects
			display flex
			container flex-start
			width 100%
			ul
				width 100%

				li
					width 100%

				li:after
					content ""
					clear both
					display block

				li + li
					margin-top 2rem

			.title
				display inline-block
				float right
				width (50% - 1rem)
				font-size (52rem/18)

			.cover
				float left
				width 50%
				margin-top 0

			.subtitle
				float right
				width (50% - 1rem)
				display inline-block
				margin-top 1rem
		*/

		#projects a
			/*display flex
			flex-wrap wrap
			justify-content space-between*/

			.cover
				margin-top 0
				float left
				width (50% - 4rem)
			.cover:after
				padding-bottom 75%

			.text-container
				order 1
				float right
				width (50% - 4rem)
				display flex
				flex-wrap wrap
				align-items center
				align-content center


				.title
					display block
					margin-top 0
					margin-bottom 1rem
					transform translate(0, 0)

					h2
						display inline
						font-size big-font
						font-size 4vw

				.subtitle
					display block


		li:after, a:after
			content ""
			clear both
			display block

	+media(desktop)
		#projects a
			.text-container .title h2
				font-size big-font

.intro
	margin-bottom 6rem

	.intro_header
		border-bottom 4px solid black
		padding 1rem 0
		margin-bottom 2rem

		h4
			font-size 2rem
			font-weight bold
			color topbar-color

	.text
		font-size 1.3rem
		column-count 1

		+media(tablet-v)
			font-size 1.6rem

		+media(tablet-h)
			font-size 1.8rem
			column-gap 2rem
			column-count 2

.updates
	border-top 4px solid black
	margin-bottom 4rem

	ul
		columns 1
		column-gap 2rem
		padding-top 2rem
		
		+media(tablet-v)
			columns 1

		li
			-webkit-column-break-inside avoid
			page-break-inside avoid /* Firefox */
			break-inside avoid
			border-bottom 1px solid line
			padding-bottom 2rem
			margin-bottom 1rem

	h5
		font-size 2rem
		margin 0 0 1rem 0
		font-weight bold
		color topbar-color

	.update_text
		font-size 1.3rem
		margin-left 0

		+media(tablet-v)
			margin-left 25%


