
#section
	+media(desktop)
		padding-top: 170px

header.header
	font-size 20px
	a[href]
		text-decoration: none

	background-color topbar-color
	.fa
		font-size 20px
	z-index 3
	position fixed
	width 100%

	#registration
		background-color white
		position fixed
		right 1rem
		top 1rem		
		padding  0.5rem
		float right
		border none
		margin 4px 2px
		text-align center
		text-decoration none
		display inline-block
		font-size 14px
		border-radius 4px
		margin 2px 2px
		position absolute
		top 7px
		right 1rem
		cursor pointer



	#hamburger
		position fixed
		top 1rem
		left 1rem


	#topbar
		font-weight 700
		width 100%
		padding 1rem
		padding-left 3rem
		background-color topbar-color

	#topbar-desktop
		display none
		
	nav#menu
		
		display none
		background-color topbar-color
		width 100%
		list-style-type: none 
		text-decoration none
		
		ul
			
			margin-top 1rem
			
			li
				
				height 2rem
				display block
				padding-left 1rem
			
			a.active
				font-weight 700

	nav#menu.open
		display block



	+media(desktop)
	
		#registration
			top 5.7rem
			right 3rem	
			background-color white
			color black
			border 2px solid topbar-color
			padding 1rem 2rem 1rem 2rem
			border-radius 4px
			font-size 14px

		#topbar
			display none

		#topbar-desktop
			font-size 16px
			font-weight 700
			display block
			width 100%
			padding 1.4rem 0.5rem 0.5rem 3rem
			padding-left 3rem
			background-color topbar-color

		#hamburger
			display none

		nav#menu
			
			background-color primary-color
			display block
			height 7rem

			ul
				padding-top 1rem
				display flex
				margin-left 1rem

				li
					display inline-block
					padding 2rem
					padding-bottom 3.7rem

				a.active
					
					border-bottom 6px solid topbar-color
					font-weight 700
			
				// a:hover
				// 	border-bottom 3px solid topbar-color
				// 	cursor pointer
		



