
light=300
regular=400
semi-bold=600
bold=700
exrta-bold=800

@font-face
	font-family Open-Sans
	font-weight bold
	src	url("../fonts/Open_Sans/OpenSans-Bold.ttf") format("truetype")

@font-face
	font-family Open-Sans
	font-style regular
	src	url("../fonts/Open_Sans/OpenSans-Regular.ttf") format("truetype")

//a
	//text-decoration: none
	//color: white

.text-font {
  font-size:30px;
  color: black;
}

h1
	//font-style: bold
	font-weight 700

h2
	 font-weight 700

h3
	 font-weight 700

p
	line-height 140%

html
	font-family Open-Sans, sans-serif
	color black
	line-height 160%
  	font-size medium-font
	font-display auto /* Define how the browser behaves during download */

big-font = 4.688rem
 font-weight 700
medium-font = (26rem/18)
small-font = (14rem/18)	// original is 12rem, but too small
h1=6.667rem

.big
	font-size 10vw
	line-height 120%

	+media(tablet-v)
		font-size big-font


.medium
	font-size medium-font

.small
	font-size small-font
	line-height 150%
 
strong 
	font-weight bold