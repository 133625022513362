.credits

	.header
		padding 2rem 0 1rem
		margin-bottom 2rem
		border-bottom 4px solid black
		font-size 2rem
		color topbar-color

	.schools
		margin-bottom 4rem

		p
			margin-bottom 1.5rem

	.people 

		div
			margin-bottom 1.5rem
		
		ul
			margin-bottom 1.5rem

			li
				margin-bottom 0.7rem

				+media(tablet-v)
					margin-bottom 0.2rem