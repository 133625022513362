.main-text

	padding-top:1.35rem
	//border-top: .25rem solid #121c21;
	width: 90%

.sub_panel_home 
	height 300px
	background-color secondary-color
	padding 2rem

.sub_panel
	color black
	font-size 2rem
	background-color secondary-color
	padding 10rem 1rem 5rem 
	line-height 140%

	+media(tablet-v)
		padding-left 2rem
		padding-right 2rem
	
	+media(desktop)
		padding-left 3rem
		padding-right 3rem

	h1
		width 100%

		+media(tablet-v)
			width 75%

	h4 
		padding-bottom 1rem
		font-size 1.2rem	
		
		a	
			text-decoration none

