.program
	div
		a
			text-decoration none

.parent_page
	position relative
	top -15px
	left 15px
	font-size 1.8rem

.program_overview
	background-color bg_color

	.the_day
		border-top 4px solid black
		border-bottom 1px solid line
		padding 2rem 0 1rem
		margin-bottom 1rem
		display flex
		justify-content space-between

		h3
			font-size 2rem
			color topbar-color
			width 80%

		.link
			position relative
			top -10px

.one_day
	margin-bottom 5rem

	em
		font-style: italic

	.event
		border-bottom 1px solid line
		margin-bottom 1rem
		padding-bottom 1rem

		.highlight
			color: topbar-color

		+media(tablet-v)
			display flex
			justify-content space-between

		.time
			padding-bottom 20px
			font-weight bold

			+media(tablet-v)
				width 25%
				padding-bottom 0

		.session
			padding-bottom 20px

			+media(tablet-v)
				width 70%
				padding-bottom 0

			.session_name
				font-weight bold

.specific_day

	.break_event
		.info
			border-bottom none !important
	
	.event
		border-top 2px solid black
		margin-bottom 1rem
		padding-bottom 1rem


		.info
			padding 1rem 0 0
			border-bottom 1px solid line

			+media(tablet-v)
				display flex
				justify-content flex-start

			.event_time
				padding-bottom 20px
				font-weight bold

				+media(tablet-v)
					width 20%
					padding 0

			.event_title_link
				display flex
				justify-content space-between
				
				+media(tablet-v)
					width 80%

				.event_title_cat
					display block
					width 100%
					margin-bottom 0.8rem

					+media(tablet-v)
						display flex
						justify-content flex-start

					.event_title
						font-weight bold
						padding 0 2rem 10px 0 

						+media(tablet-v)
							width 75%
							padding-bottom 0

				.event_link_to_page
					width 15%
					display flex
					justify-content flex-end
					position relative
					top -10px

				.event_category
					+media(tablet-v)
						width 25%

		.detail
			padding 1rem 0 0

			+media(tablet-v)
				width 70%
				padding-bottom 0
				margin 0 0 0 20%

.event_page
	background-color bg_color

	h3
		font-size 1rem
		font-weight bold

	h4
		font-size 1.5rem
		font-weight bold

	.header
		display flex
		justify-content space-between

		.time_cat
			+media(tablet-v)
				width 70%
				padding-bottom 0
				display flex
				justify-content space-between

			.event_time
				font-weight bold
				padding-bottom 10px

		.event_link
			text-align right

			+media(tablet-v)
				width 20%

	.detail

		.abstract_download

			+media(tablet-v)
				display flex
				justify-content space-between
				margin-bottom 5rem

			.abstract 
				+media(tablet-v)
					width 75%

			.keywords
				font-size 0.8rem

			.download
				margin 2rem 0 5rem
				display flex
				justify-content flex-start

				+media(tablet-v)
					justify-content flex-end
					width 20%
					text-align right
					margin 0 0 5rem

				div
					margin 0 10px 0 0

					+media(tablet-v)
						margin 0 0 0 10px


			.event_keywords
				font-weight bold
				margin .2rem 0 2.5rem
				font-size 1.5rem
				line-height 140%

			.event_description
				margin-bottom 20px
				font-size 1.2rem

		.event_speakers
			margin-bottom 5rem
			
			ul
				-moz-column-count 1
				column-count 1

				+media(tablet-v)
					width 75%
					-moz-column-count 2
					column-count 2
					-moz-column-gap 2rem
					column-gap 2rem

				a
					color black
					text-decoration none

					h3
						font-size 1.5rem
						font-weight normal
						line-height 120%

					li
						padding 1rem 0
						display flex
						justify-content space-between
						border-bottom 1px solid line
						margin-bottom 0.2rem
						page-break-inside avoid
						-moz-column-break-inside avoid
						break-inside avoid


		.event_detail
			margin-bottom 5rem

			p
				margin-bottom 1rem

			+media(tablet-v)
				width 75%

		.event_papers
			+media(tablet-v)
				width 75%

			li
				margin-bottom 2rem
				padding-bottom 2rem
				border-bottom 1px solid line

			.the_title
				font-weight bold

			.the_authors
				margin-bottom 20px

.open_section
	border-bottom 4px solid black
	padding-bottom 20px
	margin-bottom 20px

// category circle and label
.category
	text-transform uppercase
	font-weight bold

.dot
	height 15px
	width 15px
	border-radius 50%
	display inline-block
	top 2px
	position relative

.workshop_category_circle
	background-color workshop-color

.panel_category_circle
	background-color panel-color

.workshop_category
	color workshop-color

.panel_category
	color panel-color

