.speakers

	.people_group
		margin-bottom 5rem

		.header
			font-size 2rem
			color #e67753

		.group_header
			display: flex
			justify-content space-between
			border-bottom 1px solid line
			padding .8rem 0 0.5rem

			.speakers_header
				width 75%

				h4
					font-weight bold 

			.link_to_speaker_details
				position relative
				top -10px

		ul
			margin 0.8rem 0 3rem

			.person
				display: block
				margin-bottom 0.2rem

#group-name
    color black


  +media(mobile)

    width 100%
	+media(tablet-h)
		width 50%	// roule applied above tablet-h brakpoint up to the next one if present below (desktop for example)
	+media(desktop)
		width 33%  // roule applied above desktop breakpoint

.skeakers_overview
	.speakers_list

		+media(tablet-h)
			column-count 2
			column-gap 2rem

		li
			-webkit-column-break-inside avoid
			page-break-inside avoid
			break-inside avoid

	.panel_speakers, .workshop_speakers
		margin-bottom 5rem

	a
		text-decoration none
		color black

	.speakers_header
		padding 2rem 0 1rem
		margin-bottom 1rem
		border-bottom 4px solid black

		h2
			font-size 2rem
			color topbar-color
			line-height 120%

	.speaker_line a
		display flex
		justify-content space-between
		padding .7rem 0
		border-bottom 1px solid line

		+media(tablet-h)
			padding 1rem 0

		.speaker_name_aff
			padding-right 1.5rem

			.speaker_name
				font-size 1.2rem
				margin-bottom 0.2rem
				font-weight normal

				+media(tablet-h)
					font-size 1.5rem
					margin-bottom 0.5rem

			.speaker_affiliation
				font-size 0.8rem
				
				+media(tablet-h)
					font-size 1rem

			.speaker_link
				width 40px

				+media(tablet-h)
					width 70px
